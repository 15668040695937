import Button from '@gameonsports/components/cjs/Button'
import DateFormat from '@gameonsports/components/cjs/DateFormat'
import Icon from '@gameonsports/components/cjs/Icon'
import List from '@gameonsports/components/cjs/List'
import Notification from '@gameonsports/components/cjs/Notification'
import Pill from '@gameonsports/components/cjs/Pill'
import { Text } from '@gameonsports/components/cjs/TextV3'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  DiscoverCompetitionsSeasons,
  DiscoverSeasonStatusType,
  OrganisationType,
} from '../../generated/graphql'
import slugify from '../../utils/slugify'
import { media } from '../../utils/styled-components-utils'
import { Link } from '../Router'

const SeasonDates = styled(Text)`
  grid-area: dates;
  padding-bottom: 1rem;

  ${media.tablet`
    padding-left: 0;
    padding-bottom: 0;
  `}
`

const Indicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 4rem;
  grid-area: indicator;
  transition: background-color 150ms ease;
  background-color: ${props => props.theme.blackberry100};

  svg {
    fill: ${props => props.theme.tertiary};
    transition: fill 150ms ease;
  }

  ${media.tablet`
    display: none;
  `}
`

const SelectButton = styled(Button)`
  display: none;

  ${media.tablet`
    display: block;
  `}
`

const SeasonListItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.bodyBackground};
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.bodyCopy};
  overflow: hidden;
  padding: 0 0 0 1rem;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  margin-top: -1px;
  transition: background-color 150ms ${props => props.theme.easeInOutCirc};

  @supports (display: grid) {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 5rem 2rem;
    grid-row-gap: 1rem;
    grid-template-areas:
      'name status indicator'
      'dates dates indicator';
  }

  &:hover,
  &:focus {
    background-color: ${props => props.theme.blackberry100};

    ${SelectButton} {
      background-color: ${props => props.theme.buttonTertiary};
      color: ${props => props.theme.white400};
    }
  }

  ${media.tablet`
    grid-template-columns: 1.5fr 2fr 1fr auto;
    grid-template-areas: 'name dates status indicator';
    padding: 0 1.5rem;
    height: 3.75rem;
  `}
`

const SeasonTitle = styled(Text)`
  margin-top: 0;
  margin-bottom: 1rem;

  ${media.tablet`
    margin-bottom: 1.75rem;
  `}
`

const SeasonName = styled(Text)`
  padding-top: 1rem;
  grid-area: name;

  ${media.tablet`
    padding-top: 0;
  `}
`

const SeasonStatus = styled.div`
  display: flex;
  align-items: center;
  grid-area: status;
  padding-top: 1rem;
  justify-self: end;

  ${media.tablet`
    padding-top: 0;
    justify-self: start;
  `}
`

const SeeMore = styled(Text).attrs({
  as: 'button',
  fontStyle: 'italic',
  size: '14',
})`
  background: none;
  border: none;
  padding: 0;
  margin: 0 auto;
  cursor: pointer;
  display: block;
`

interface SeasonStatusListProps {
  name: string
  seasons: DiscoverCompetitionsSeasons[]
  organisation: {
    id: string
    name: string
    type: OrganisationType | null
  }
  tenant: string
  statusFilter?: DiscoverSeasonStatusType[]
}

const SeasonStatusList: React.FC<SeasonStatusListProps> = ({
  name,
  seasons,
  organisation,
  tenant,
  statusFilter,
}) => {
  const [amountToShow, setAmount] = useState(3)

  const filteredSeasons = statusFilter
    ? seasons.filter(s => statusFilter.includes(s.status.value))
    : seasons

  return (
    <div>
      <SeasonTitle weight="700" size="18" as="h2">
        {name}
      </SeasonTitle>

      {seasons.length === 0 && (
        <Notification variant="empty">
          There are currently no seasons for this competition.
        </Notification>
      )}

      {filteredSeasons.length === 0 && seasons.length > 0 && (
        <Notification variant="empty">
          There are no seasons available for the selected filters.
        </Notification>
      )}

      <List>
        {filteredSeasons.slice(0, amountToShow).map(season => (
          <li key={season.id}>
            <SeasonListItem
              to={
                organisation.type === OrganisationType.Club
                  ? `/${tenant}/org/${slugify(organisation.name)}/${
                      organisation.id
                    }/${slugify(`${name} ${season.name}`)}/${season.id}/teams`
                  : `/${tenant}/org/${slugify(organisation.name)}/${slugify(
                      `${name} ${season.name}`,
                    )}/${season.id}`
              }
              data-testid={`season-${season.id}-item`}
            >
              <SeasonName size="16" weight="600">
                {season.name}
              </SeasonName>
              <SeasonDates size="16" color="darkGrey400">
                <DateFormat date={season.startDate} /> —{' '}
                <DateFormat date={season.endDate} />
              </SeasonDates>
              <SeasonStatus>
                <Pill
                  variant={
                    season.status.value === DiscoverSeasonStatusType.Active
                      ? 'active'
                      : season.status.value ===
                        DiscoverSeasonStatusType.Upcoming
                      ? 'upcoming'
                      : 'complete'
                  }
                  small
                  text={season.status.name}
                />
              </SeasonStatus>
              <SelectButton
                variant="tertiary"
                halo
                icon="right-arrow-strong"
                iconPosition="right"
                iconSize="12"
                size="small"
              >
                Select
              </SelectButton>
              <Indicator>
                <Icon name="right-arrow" />
              </Indicator>
            </SeasonListItem>
          </li>
        ))}
      </List>

      {filteredSeasons.length > amountToShow && (
        <SeeMore
          onClick={() => setAmount(amountToShow + 2)}
          data-testid="see-more"
        >
          See more <Icon name="down-arrow" size="8" />
        </SeeMore>
      )}
    </div>
  )
}

export default SeasonStatusList
